<template>
  <ValidationObserver
    class="bg-white modal-card rounded"
    style="width: 50vw"
    v-slot="{ invalid }"
  >
    <div class="bg-primary-100 p-4 text-xl">Création d'un sondage</div>
    <div
      class="grid grid-cols-4 gap-3 p-5 overflow-y-auto"
      style="max-height: 80vh"
    >
      <div class="col-span-4">
        <ValidationProvider
          name="Question"
          rules="required|min:10|max:180"
          v-slot="{ errors }"
        >
          <t-input-group
            label="Titre"
            :variant="{ 'danger': errors[0] }"
            :feedback="errors[0]"
          >
            <t-input
              v-model="survey.question"
              :variant="{ 'danger': errors[0] }"
            />
          </t-input-group>
        </ValidationProvider>
      </div>
      <div class="col-span-4 flex flex-col gap-5">
        <div
          v-for="(option, i) in survey.options"
          :key="i"
        >
          <ValidationProvider
            class="flex justify-between"
            :name="`Réponse ${i + 1}`"
            rules="required|min:3|max:180"
            v-slot="{ errors }"
          >
            <t-input-group
              class="w-full"
              :label="`Réponse ${i + 1}`"
              :variant="{ 'danger': errors[0] }"
              :feedback="errors[0]"
            >
              <t-input
                v-model="survey.options[i]"
                placeholder="Choisir une réponse..."
                :variant="{ 'danger': errors[0] }"
                :disabled="['Ne souhaite pas répondre', 'Autre'].includes(survey.options[i])"
              />
            </t-input-group>
            <div
              v-if="i > 1"
              class="col-span-1 p-3 flex items-end justify-center"
              @click="removeOption(i)"
            >
              <i class="fas fa-times text-red-700" />
            </div>
          </ValidationProvider>
        </div>
        <div class="flex space-x-2 text-sm">
          <t-button
            @click="addOption"
            class="w-full h-full"
            :class="!survey.options.some(option => option.trim() === '')
                    ? 'transform transition-transform duration-300 hover:-translate-y-1 hover:scale-105'
                    : ''"
            :disabled="survey.options.some(option => option.trim() === '')"
          >
            Ajouter une réponse classique
          </t-button>
          <t-button
            @click="addCustomAnswerOption"
            class="w-full h-full"
            :class="!survey.options.includes('Autre')
                    ? 'transform transition-transform duration-300 hover:-translate-y-1 hover:scale-105'
                    : ''"
            :disabled="survey.allows_custom_answer"
          >Ajouter une réponse personnalisée</t-button>
          <t-button
            @click="addNoReplyOption"
            class="w-full h-full"
            :class="!survey.options.includes('Ne souhaite pas répondre')
                    ? 'transform transition-transform duration-300 hover:-translate-y-1 hover:scale-105'
                    : ''"
            :disabled="survey.noReplyAdded"
          >Ajouter une réponse "ne souhaite pas répondre"</t-button>
        </div>
        <div class="flex space-x-8">
          <t-input-group label="Plusieurs réponses possibles">
            <t-toggle v-model="survey.multiple_answers" />
          </t-input-group>
          <t-input-group label="Possibilité de changer de réponse">
            <t-toggle v-model="survey.is_reanswerable" />
          </t-input-group>
        </div>
        <t-input-group
          label="Groupes (facultatif)"
          v-if="usersGroups && usersGroups.length"
        >
          <t-rich-select
            multiple
            :close-on-select="false"
            :options="usersGroups"
            placeholder="Sélectionner des groupes"
            v-model="groupsSelected"
          ></t-rich-select>
        </t-input-group>
      </div>
      <ValidationProvider
        class="col-span-4 md:col-span-2"
        name="Début du sondage"
        rules="required"
        v-slot="{ errors }"
        v-if="domainUuid !== '2bc5decc-cd2e-46ad-92d7-f17234fdeebd'"
      >
        <t-input-group
          label="Début du sondage"
          :variant="{ 'danger': errors[0] }"
          :feedback="errors[0]"
        >
          <t-datepicker
            timepicker
            required
            class="mb-10"
            v-model="survey.start_at"
            :maxDate="survey.end_at"
            placeholder="Clique pour séléctionner la date de début du sondage..."
            dateFormat="Y-m-d H:i:S"
            userFormat="d/m/Y H:i"
          />
        </t-input-group>
      </ValidationProvider>
      <ValidationProvider
        class="col-span-4 md:col-span-2"
        name="Fin du sondage"
        rules="required"
        v-slot="{ errors }"
        v-if="domainUuid !== '2bc5decc-cd2e-46ad-92d7-f17234fdeebd'"
      >
        <t-input-group
          label="Fin du sondage"
          :variant="{ 'danger': errors[0] }"
          :feedback="errors[0]"
        >
          <t-datepicker
            timepicker
            required
            v-model="survey.end_at"
            :minDate="survey.start_at"
            placeholder="Clique pour séléctionner la date de fin du sondage..."
            dateFormat="Y-m-d H:i:S"
            userFormat="d/m/Y H:i"
          />
        </t-input-group>
      </ValidationProvider>
    </div>
    <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-between">
      <div class="flex gap-3 items-center">
        <label for="active">Actif</label>
        <t-toggle
          class="ml"
          v-model="survey.is_enabled"
        ></t-toggle>
      </div>
      <div v-if="domain.can_avoid_notifications" class="ml-8 flex gap-3 items-center">
        <label>Ne pas notifier</label>
        <t-toggle
          class=""
          v-model="survey.to_notify"
          :value="false"
          :uncheckedValue="true"
        />
      </div>
      <div class="flex gap-3">
        <t-button
          class="m-0"
          @click.prevent="$parent.close()"
          variant="secondary"
        >Annuler</t-button>
        <t-button
          :disabled="invalid"
          variant="success"
          @click.prevent="create"
        >Sauvegarder</t-button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'

export default {
  name: 'app-surveys-create',
  props: { domainUuid: String },
  data () {
    return {
      survey: {
        question: '',
        options: ['', ''],
        multiple_answers: false,
        is_reanswerable: false,
        start_at: null,
        end_at: null,
        is_enabled: false,
        to_notify: true,
        allows_custom_answer: false,
        noReplyAdded: false
      },
      usersGroups: [],
      groupsSelected: []
    }
  },
  async beforeMount () {
    // Fetch groups
    await this.fetchGroups()
  },
  computed: {
    ...mapGetters([
      'domain'
    ])
  },
  methods: {
    async fetchGroups () {
      try {
        const response = await http.get('/groups')
        this.usersGroups = response.data.map(group => ({
          value: group.uuid,
          text: group.label
        }))
      } catch (error) {
        console.error('Error fetching groups:', error)
      }
    },
    create () {
      const formData = new FormData()
      formData.append('question', this.survey.question)
      formData.append('multiple_answers', this.survey.multiple_answers)
      formData.append('is_reanswerable', this.survey.is_reanswerable)
      formData.append('start_at', this.survey.start_at)
      formData.append('end_at', this.survey.end_at)
      formData.append('is_enabled', this.survey.is_enabled)
      formData.append('to_notify', this.survey.to_notify)
      formData.append('allows_custom_answer', this.survey.allows_custom_answer)

      this.survey.options.forEach(option => {
        formData.append('options[]', option)
      })

      this.groupsSelected.forEach(groupUuid => {
        formData.append('groupUuids[]', groupUuid)
      })

      http.post('/surveys', formData).then(({ data }) => {
        this.$emit('created', data)
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à créer ce sondage',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      }).finally(() => {
        this.$parent.close()
      })
    },
    addOption () {
      if (this.survey.options.some(option => option.trim() === '')) return
      const specialOptions = ['Ne souhaite pas répondre', 'Autre']
      const specialIndex = this.survey.options.findIndex(option => specialOptions.includes(option))
      if (specialIndex > -1) {
        this.survey.options.splice(specialIndex, 0, '')
      } else {
        this.survey.options.push('')
      }
    },
    removeOption (index) {
      const option = this.survey.options[index]
      if (option === 'Ne souhaite pas répondre') {
        this.survey.noReplyAdded = false
      }
      if (option === 'Autre') {
        this.survey.allows_custom_answer = false
      }
      this.survey.options.splice(index, 1)
    },
    addNoReplyOption () {
      this.removeNoReplyOption()
      this.survey.options.push('Ne souhaite pas répondre')
      this.survey.noReplyAdded = true
    },
    removeNoReplyOption () {
      const index = this.survey.options.indexOf('Ne souhaite pas répondre')
      if (index > -1) {
        this.survey.options.splice(index, 1)
      }
      this.survey.noReplyAdded = false
    },
    addCustomAnswerOption () {
      this.removeCustomAnswerOption()
      this.survey.options.push('Autre')
      this.survey.allows_custom_answer = true
    },
    removeCustomAnswerOption () {
      const index = this.survey.options.indexOf('Autre')
      if (index > -1) {
        this.survey.options.splice(index, 1)
      }
      this.survey.allows_custom_answer = false
    }
  }
}
</script>
